import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { FiArrowLeft } from '@react-icons/all-files/fi/FiArrowLeft'
import UnstyledButton from 'components/unstyled-button'

const TitleBar = ({ children, isMainTitle = true, previousPage, ...props }) => {
  return (
    <StyledTitleBar {...props}>
      <UnstyledButton
        onClick={() => navigate(previousPage ? previousPage : -1)}
        className='back-button'
      >
        <FiArrowLeft aria-hidden='true' focusable='false' role='presentation' />
        <span className='visually-hidden'>Go back</span>
      </UnstyledButton>
      {isMainTitle ? (
        <h1 className='title'>{children}</h1>
      ) : (
        <span className='title'>{children}</span>
      )}
    </StyledTitleBar>
  )
}

TitleBar.propTypes = {
  children: PropTypes.node.isRequired,
  isMainTitle: PropTypes.bool,
  previousPage: PropTypes.string,
}

const StyledTitleBar = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid var(--color-n30);
  display: flex;
  flex-direction: row;
  padding: var(--size-s);
  position: relative;
  width: 100%;

  .back-button {
    display: inline-block;
    margin-right: var(--size-s);

    :hover,
    :focus,
    :active {
      svg {
        color: var(--color-n100);
      }
    }
  }

  svg {
    font-size: var(--font-size-h5);
  }

  .title {
    display: inline-block;
    font-size: var(--font-size-h5);
    font-weight: 600;
  }
`

export default TitleBar
