import React from 'react'
import Template from 'templates/category'
import { graphql } from 'gatsby'

const SubcategoryQueryTemplate = props => {
  return <Template {...props} />
}

export const pageQuery = graphql`
  query subcategoryQueryTemplate($id: String!) {
    category: sanityFieldGuideSubcategory(id: { eq: $id }) {
      parentCategory {
        slug
        title
      }
      title
    }
    items: allSanityFieldGuidePost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        slug
        title
      }
    }
  }
`

export default SubcategoryQueryTemplate
