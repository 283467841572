import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Layout from 'components/layout'
import TitleBar from 'components/title-bar'
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward'

const CategoryTemplate = props => {
  const {
    data: { category, items },
    postListComponents,
    isHomepage = false,
  } = props

  const headTitle = category
    ? `${category.parentCategory ? `${category.parentCategory.title} - ` : ''}${category.title}`
    : null

  return (
    <Layout seo={{ title: headTitle }} isHomepage={isHomepage}>
      {category ? (
        <TitleBar previousPage={category.parentCategory ? category.parentCategory.slug : '/'}>
          {category.title}
        </TitleBar>
      ) : null}
      <List>
        {items.nodes.map(item => (
          <li key={item.slug}>
            <Link to={item.slug}>
              <span className='list-item-title'>{item.title}</span>
              <IoIosArrowForward aria-hidden='true' focusable='false' role='presentation' />
            </Link>
          </li>
        ))}
      </List>
      {postListComponents ? postListComponents : null}
    </Layout>
  )
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;

    a {
      align-items: center;
      background-color: var(--color-n10);
      border-bottom: 1px solid var(--color-n30);
      color: var(--color-n500);
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: var(--size-s);
      text-decoration: none;

      :hover,
      :focus {
        background-color: var(--color-n20);
        color: var(--color-n700);

        svg {
          color: var(--color-n300);
        }
      }
    }

    .list-item-title {
      color: inherit;
      font-size: var(--font-size-h5);
      font-weight: 600;
      padding-right: var(--size-s);
    }

    svg {
      color: var(--color-n100);
      flex-shrink: 0;
      font-size: 1rem;
    }
  }
`

export default CategoryTemplate
